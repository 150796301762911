<template>
  <div v-if="options && data" class="chart-header">
    <div class="img-container"></div>
    <div class="header-wrapper">
      <p>{{ title ? title : 'Energy and Water Production' }}</p>
      <div class="dropdown">
        <button @click="toggleDropdown" class="dropbtn">
          <img :src="$menuIcon" alt="" />
        </button>
        <div :id="`myDropdown${idx}`" class="dropdown-content">
          <a @click="openFullscreen">View in Full Screen</a>
          <a @click="printChart">Print Chart</a>
          <hr style="margin: 0" />
          <a v-for="ext in fileExtensions" :key="ext" @click="saveChartAs(ext)"
            >Download as {{ ext }}</a
          >
          <a @click="downloadCSV">Download as an Excel</a>
        </div>
      </div>
    </div>
  </div>
  <div class="analytics-wrapper" :id="`chart-wrapper${idx}`">
    <Chart
      type="chart"
      :options="options"
      :data="data"
      :id="`analytics-chart${idx}`"
      ref="analyticsMainChart"
      style="width: 100%"
    ></Chart>
  </div>
</template>
<script>
import {
  Chart as ChartJS,
  CategoryScale,
  ArcElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  Filler,
} from 'chart.js'
import { Chart, Bar, Scatter, Line } from 'vue-chartjs'
import {
  openChartFullScreen,
  printCurrChart,
  saveChartToFile,
} from '../services/utils.js'
import { read, utils, writeFile } from 'xlsx'
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
)
export default {
  props: {
    options: Object,
    data: Object,
    idx: Number,
    title: String,
  },
  data() {
    return {
      fileExtensions: ['PNG', 'JPEG'],
    }
  },
  components: {
    Chart,
    Bar,
    Scatter,
    Line,
  },
  methods: {
    printChart() {
      printCurrChart(`analytics-chart${this.idx}`)
    },
    async saveChartAs(ext) {
      saveChartToFile(ext, `analytics-chart${this.idx}`)
    },
    openFullscreen() {
      openChartFullScreen(`chart-wrapper${this.idx}`)
    },
    toggleDropdown() {
      document.getElementById(`myDropdown${this.idx}`).classList.toggle('show')
    },
    downloadCSV() {
      const { labels, datasets } = this.data
      const sheets = {}
      datasets.forEach((dataset, index) => {
        var dataForNow = dataset.data.map((data, idx) => {
          return {
            date: labels[idx],
            data: data,
          }
        })
        const worksheet = utils.json_to_sheet(dataForNow)
        sheets[dataset.label] = worksheet
      })
      const workbook = utils.book_new()
      Object.keys(sheets).forEach((sheetName) => {
        utils.book_append_sheet(workbook, sheets[sheetName], sheetName)
      })
      writeFile(workbook, `${this.title}.xlsx`)
    },
    updateChart() {
      // console.log(this.$refs.analyticsMainChart.chart.update)
    },
  },
}
</script>

<style lang="scss" scoped>
.chart-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .img-container {
    height: 1rem;
    width: 1rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .header-wrapper {
    width: 95%;
    display: flex;
    justify-content: space-between;
    p {
      margin: 0;
      padding: 0;
    }
  }
}

.dropdown {
  position: relative;
  display: inline-block;

  .dropbtn {
    color: white;
    border: none;
    cursor: pointer;
    padding: 3px;
    margin: 0;
    border-radius: 0;
    display: flex;
    align-items: center;
    outline: none;
    img {
      height: 16px;
      width: 16px;
    }

    &:hover {
      background-color: rgb(100, 100, 100);
      outline: none;
    }
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  width: 130px;
  z-index: 1;
  right: 0%;

  a {
    font-size: 12px;
    color: black;
    padding: 4px;
    text-decoration: none;
    display: block;
    &:hover {
      background-color: #ddd;
    }
  }
}

.show {
  display: block;
}

.analytics-wrapper {
        width: 100%;
        height: 300px;
        display: flex;
        align-items: center;
        overflow: hidden;
        justify-content: center;
        background-color: white;
      }
</style>
<!-- <div style="display: none">
    <table id="myTable">
      <thead>
        <tr>
          <td>Category</td>
          <td v-for="title in data.datasets" :key="title.label">
            {{ title.label }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(label, idx) in data.labels" :key="label">
          <td>{{ label }}</td>
          <td>{{ data.datasets[0].data[idx] }}</td>
          <td>{{ data.datasets[1].data[idx] }}</td>
        </tr>
      </tbody>
    </table>
  </div> -->
