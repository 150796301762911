import html2canvas from "html2canvas";

export const openChartFullScreen = (elId) => {
  var el = document.getElementById(elId);

  if (el.requestFullscreen) {
    el.requestFullscreen();
  } else if (el.webkitRequestFullscreen) {
    /* Safari */ p;
    el.webkitRequestFullscreen();
  } else if (el.msRequestFullscreen) {
    /* IE11 */
    el.msRequestFullscreen();
  }
};

export const saveChartToFile = async (ext, elId) => {
  let currExt = ext.toLowerCase();
  var el = document.getElementById(elId);
  const options = {
    type: "dataURL",
  };
  const printCanvas = await html2canvas(el, options);

  const link = document.createElement("a");
  link.setAttribute("download", `download.${currExt}`);
  link.setAttribute(
    "href",
    printCanvas
      .toDataURL(`image/${currExt}`)
      .replace(`image/${currExt}`, "image/octet-stream")
  );
  link.click();

  console.log("done");
};

export const printCurrChart = (elId) => {
  var canvasEle = document.getElementById(elId);
  var win = window.open("", "Print", "height=600,width=800");
  win.document.write("<br><img src='" + canvasEle.toDataURL() + "' />");
  setTimeout(function () {
    win.document.close();
    win.focus();
    win.print();
    win.location.reload();
  }, 200);
};


// Not Working
export const tableToExcel = (table) => {
  console.log(table);
  var uri = "data:application/vnd.ms-excel;base64,",
    template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
    base64 = function (s) {
      return window.btoa(unescape(encodeURIComponent(s)));
    },
    format = function (s, c) {
      return s.replace(/{(\w+)}/g, function (m, p) {
        return c[p];
      });
    };
  if (!table.nodeType) table = document.getElementById(table);
  var ctx = { worksheet: name || "Worksheet", table: table.innerHTML };
  window.location.href = uri + base64(format(template, ctx));
};
